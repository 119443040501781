import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { TranslateService } from '@ngx-translate/core';
import { firstValueFrom } from 'rxjs';
import { CentralServerService } from 'services/central-server.service';
import { SpinnerService } from 'services/spinner.service';
import { BillingInvoice } from 'types/Billing';
import { ChargingStation, OCPPGeneralResponse } from 'types/ChargingStation';
import { ActionResponse, Paging } from 'types/DataResult';
import { FilterParams } from 'types/GlobalType';
import { Transaction } from 'types/Transaction';
import { Utils } from 'utils/Utils';

@Component({
  selector: 'app-charging',
  templateUrl: 'charging.component.html',
  styleUrls: ['charging.component.scss']
})
export class ChargingComponent implements OnInit, OnDestroy {
  tagId: string;
  userId: string;
  transactionId: number | null = null;
  transactionDetails: Transaction;
  formattedDuration: string;
  energyInKWh: number;
  intervalId: any;
  isTransactionStopped = false;
  chargingStationId: string;
  connectorId: number;
  chargingStation: ChargingStation;
  public connectorStatus: string;
  public connectorExists = true;
  invoiceUrl: string;
  public isLoadingInvoice = true;

  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private centralServerService: CentralServerService,
    private spinnerService: SpinnerService,
    private translateService: TranslateService
  ) {}

  ngOnInit() {
    const language = localStorage.getItem('language');
    const supportedLanguages = ['en', 'fr', 'es', 'de', 'it', 'pt', 'cs', 'cz'];
    const baseLanguage = language.split('-')[0];
    const languageToUse = supportedLanguages.includes(baseLanguage) ? baseLanguage : 'en';
    this.translateService.use(languageToUse);
    const storedTransactionId = sessionStorage.getItem('transactionId');
    this.tagId = sessionStorage.getItem('tagId');
    this.userId = sessionStorage.getItem('userId');
    this.transactionId = storedTransactionId ? parseInt(storedTransactionId, 10) : null;
    this.route.params.subscribe(params => {
      this.chargingStationId = params['entityId'];
      this.connectorId = params['connectorId'];
    });
    if (this.transactionId) {
      this.getTransactionDetails();
    }
    this.loadChargingStation(this.chargingStationId,this.connectorId);
    this.intervalId = setInterval(() => {
      if (this.transactionId) {
        this.getTransactionDetails();
      } else {
        return null;
      }
    }, 10000);
  }

  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  redirectToEv24(): void {
    window.location.href = 'https://www.ev24.io/support/';
  }

  public stopTransaction(): void {
    this.spinnerService.show();
    this.centralServerService.stopTransaction(this.transactionId).subscribe({
      next: (res: ActionResponse) => {
        this.spinnerService.hide();
        if (res.status === OCPPGeneralResponse.ACCEPTED) {
          console.log(`Transaction ${this.transactionId} stopped successfully.`);
          this.isTransactionStopped = true;
          if (this.intervalId) {
            clearInterval(this.intervalId);
          }

          this.pollForInvoice(this.transactionDetails.user.id, this.transactionDetails.id);
        } else {
          console.error('Failed to stop transaction');
        }
      },
      error: (error) => {
        this.spinnerService.hide();
        console.error('Error stopping transaction:', error);
      }
    });
  }


  public ViewInvoice(): void {
    if (this.invoiceUrl) {
      window.open(this.invoiceUrl, '_blank');
    } else {
      console.log('Invoice URl nit found');
    }
  }

  public async getInvoiceByTransactionID(userID: string, transactionID: number): Promise<boolean> {
    return new Promise((resolve) => {
      this.spinnerService.show();
      console.log('Checking for invoice ~ transactionID:', transactionID);
      const paging: Paging = { limit: 100, skip: 0 };

      this.centralServerService.getInvoices({ userID }, paging).subscribe({
        next: (invoices) => {
          console.log('Received invoices:', invoices);
          if (invoices && invoices.result.length > 0) {
            let foundInvoice: BillingInvoice | undefined;
            for (const invoice of invoices.result) {
              const session = invoice.sessions.find(s => s.transactionID === transactionID);
              if (session) {
                foundInvoice = invoice;
                console.log('Invoice found:', foundInvoice);
                this.sendInvoiceToUser(foundInvoice);
                resolve(true);
                return;
              }
            }
          }
          console.log('No invoice found for this transaction ID.');
          resolve(false);
        },
        error: (error) => {
          console.error('Error fetching invoice:', error);
          resolve(false);
        }
      });
      this.spinnerService.hide();
    });
  }

  public logout() {
    this.centralServerService.logout().subscribe({
      next: () => {
        this.centralServerService.clearLoginInformation();
      },
      error: (error) => {
        this.centralServerService.clearLoginInformation();
      }
    });
  }


  // public getInvoiceByUserID(userID: string): void {
  //   this.spinnerService.show();
  //   console.log('ChargingComponent ~ this.centralServerService.getInvoices ~ userID:', userID);
  //   this.centralServerService.getInvoices({ userID }).subscribe({
  //     next: (invoices) => {
  //       console.log('ChargingComponent ~ this.centralServerService.getInvoices ~ invoices:', invoices);
  //       if (invoices && invoices.result.length > 0) {
  //         const invoice = invoices.result[0];
  //         console.log('Invoice:', invoice.sessions[0].transactionID);
  //         this.sendInvoiceToUser(invoice);
  //       } else {
  //         console.log('No invoices found for this user.');
  //       }
  //     },
  //     error: (error) => {
  //       console.error('Error fetching invoice:', error);
  //     }
  //   });
  //   this.spinnerService.hide();
  // }

  public async startTransaction(): Promise<void> {
    try {
      console.log('Starting transaction...');
      this.spinnerService.show();
      const response = await this.centralServerService.startTransaction(this.chargingStationId, this.connectorId, this.userId, this.tagId).toPromise();

      if (response.status === OCPPGeneralResponse.ACCEPTED) {
        console.log('Transaction started successfully');
        await new Promise(resolve => setTimeout(resolve, 3000));
        let retryCount = 0;
        const maxRetries = 5;
        while (retryCount < maxRetries) {
          this.transactionId = await this.getUserTransactions();
          if (this.transactionId) {
            break;
          }
          retryCount++;
          await new Promise(resolve => setTimeout(resolve, 2000));
        }

        if (this.transactionId) {
          const transactionString = this.transactionId.toString();
          sessionStorage.setItem('transactionId', transactionString);
          this.getTransactionDetails();
        } else {
          console.error('Transaction ID is null or undefined after retries');
        }
      } else {
        console.error('Failed to start transaction');
      }
    } catch (error) {
      console.error('Error starting transaction:', error);
    } finally {
      this.spinnerService.hide();
    }
  }


  public async getUserTransactions(): Promise<number | null> {
    try {
      const userId = sessionStorage.getItem('userId');
      const filterParams: FilterParams = { UserID: userId };
      console.log('ChargingComponent ~ getUserTransactions ~ userId:', userId);
      const transactionResult = await firstValueFrom(this.centralServerService.getActiveTransactions(filterParams));
      console.log('ChargingComponent ~ getUserTransactions ~ filterParams:', filterParams);
      if (transactionResult.result.length > 0) {
        console.log('Transaction ID retrieved:', transactionResult.result[0].id);
        return transactionResult.result[0].id;
      } else {
        console.log('No active transactions found for user');
        return null;
      }
    } catch (err) {
      console.error('Error retrieving transactions:', err);
      return null;
    }
  }

  public getTransactionDetails(): void {
    this.centralServerService.getTransaction(this.transactionId).subscribe({
      next: (transaction) => {
        this.transactionDetails = transaction;
        console.log('ChargingComponent ~ this.centralServerService.getTransaction ~ this.transactionDetails:', this.transactionDetails);
        this.formattedDuration = this.convertSecondsToMinutesAndSeconds(transaction.currentTotalDurationSecs);
        this.energyInKWh = this.convertWattsToKWh(transaction.currentTotalConsumptionWh);
      },
      error: (error) => {
        console.error('Error fetching transaction details:', error);
      }
    });
  }

  private loadChargingStation(entityId: string, connectorId: number): void {
    this.centralServerService.getChargingStation(entityId).subscribe({
      next: (chargingStation: ChargingStation) => {
        this.chargingStation = chargingStation;
        console.log('ChargingComponent ~ this.centralServerService.getChargingStationQr ~ chargingStation:', chargingStation);
        this.connectorExists = chargingStation.connectors.some((connector) => connector.connectorId === connectorId);
        this.connectorId = connectorId;
        const connectorTest = Utils.getConnectorFromID(chargingStation, connectorId);

        if (connectorTest) {
          const statusKey = `connectorStatus.${connectorTest.status.toUpperCase()}`;
          this.translateService.get(statusKey).subscribe((translatedStatus: string) => {
            this.connectorStatus = translatedStatus || connectorTest.status;
            this.connectorExists = true;
          });
        } else {
          this.connectorExists = false;
          this.connectorStatus = '';
        }

        if (!this.connectorExists) {
          this.spinnerService.hide();
        }
      },
      error: (error) => {
        console.error('Failed to load charging station:', error);
        this.spinnerService.hide();
        this.connectorExists = false;
        this.connectorStatus = '';
      },
    });
  }

  private pollForInvoice(userID: string, transactionID: number): void {
    let retries = 0;
    const maxRetries = 10;
    const pollInterval = 5000;
    this.isLoadingInvoice = true;

    const invoicePolling = setInterval(() => {
      if (retries >= maxRetries) {
        clearInterval(invoicePolling);
        this.isLoadingInvoice = false;
        console.error('Max retries reached. No invoice found.');
        this.spinnerService.hide();
        return;
      }

      this.getInvoiceByTransactionID(userID, transactionID).then((invoiceFound) => {
        if (invoiceFound) {
          clearInterval(invoicePolling);
          this.isLoadingInvoice = false;
          this.spinnerService.hide();
          this.logout();
        }
      });

      retries++;
    }, pollInterval);
  }
  private sendInvoiceToUser(invoice: any): void {
    this.spinnerService.show();
    this.centralServerService.getInvoice(invoice.id).subscribe({
      next: (invoiceBlob) => {
        const reader = new FileReader();
        reader.onload = () => {
          const invoiceData = JSON.parse(reader.result as string);
          this.invoiceUrl = invoiceData.downloadUrl;
          const userEmail = this.transactionDetails.user.email;
          console.log(`Simulating sending invoice to ${userEmail} with download link: ${this.invoiceUrl}`);
        };
        reader.onerror = (error) => {
          console.error('Error reading the invoice blob:', error);
        };
        reader.readAsText(invoiceBlob);
      },
      error: (error) => {
        console.error('Error fetching invoice:', error);
      }
    });
    sessionStorage.removeItem('transactionId');
    sessionStorage.removeItem('tagId');
    sessionStorage.removeItem('userId');
    this.spinnerService.hide();
    this.logout();
  }

  private convertSecondsToMinutesAndSeconds(seconds: number): string {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = Math.floor(seconds % 60);
    return `${minutes}m ${remainingSeconds}s`;
  }

  private convertWattsToKWh(watts: number): number {
    return watts / 1000;
  }

}
