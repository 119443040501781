<div class="main-content flex-grow-1 marginn">
  <mat-grid-list cols="3" rowHeight="1.2:1">
    <mat-grid-tile class="title1">
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="goBack()">{{'payment.back' | translate}}</button>
      </div>
    </mat-grid-tile>
    <mat-grid-tile class="title1">{{'payment.payment' | translate}}</mat-grid-tile>
    <mat-grid-tile> 
      <div class="example-button-row">
        <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
      </div>
    </mat-grid-tile>
  </mat-grid-list>

  <span style="margin-left: 5px;">{{'payment.cryptee' | translate}}</span>

  <mat-grid-list cols="2" rowHeight="2:2" class="credit-card">
    <mat-radio-group aria-label="Select an option" [(ngModel)]="selectedValue">
      <mat-radio-button value="1" style="padding: 20px;">{{'payment.credit_card' | translate}}</mat-radio-button>
    </mat-radio-group>
  </mat-grid-list>

  <mat-grid-list cols="2" rowHeight="2:2" class="credit-card-details">
    <div class="box">
      <section class="card pa-50">
        <!-- Form starts here -->
        <form class="form marginn" [formGroup]="cardForm" (ngSubmit)="onSubmit()">
          <div>
            <div class="row">
              <div class="col-md-12">
                <div class="form-group">
                  <mat-form-field>
                    <label>{{'settings.billing.payment_methods_card' | translate}}</label>
                    <span id="cardNumber">
                      <input matInput/>
                    </span>
                  </mat-form-field>
                  <mat-error *ngIf="cardNumberError">
                    {{cardNumberError}}
                  </mat-error>
                </div>
              </div>

              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field>
                    <label>{{'settings.billing.payment_methods_expiring_on' | translate}}</label>
                    <span id="cardExp">
                      <input matInput>
                    </span>
                  </mat-form-field>
                  <mat-error *ngIf="expirationDateError">
                    {{expirationDateError}}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-6">
                <div class="form-group">
                  <mat-form-field>
                    <label>
                      {{'settings.billing.payment_methods_verification_code' | translate}}
                    </label>
                    <span id="cardCvc">
                      <input matInput/>
                    </span>
                  </mat-form-field>
                  <mat-error *ngIf="cvcError">
                    {{cvcError}}
                  </mat-error>
                </div>
              </div>
              <div class="col-md-12">
                <div class="form-group">
                  <mat-checkbox formControlName="acceptConditions">
                      {{'settings.billing.payment_methods_check' | translate}}
                  </mat-checkbox>
                  <mat-error *ngIf="cardForm.controls['acceptConditions'].invalid && cardForm.controls['acceptConditions'].touched">
                    {{'payment.conditions' | translate}}
                  </mat-error>
                </div>
              </div>
            </div>
          </div>
          <!-- Email -->
          <label class="mt-20" style="width: 100%;">
            <input
              type="email"
              autocomplete="off"
              formControlName="email"
              class="white large outlined"
              [placeholder]="'authentication.email' | translate"
            />
            <div *ngIf="cardForm.controls['email'].invalid && cardForm.controls['email'].touched" class="error">
              <small *ngIf="cardForm.controls['email'].errors?.email">{{'payment.invalide' | translate}}</small>
            </div>
          </label>

          <!-- Submit Button -->
          <mat-grid-list cols="1" rowHeight="2:2">
            <button class="submit"
                    type="submit"
                    >
              {{'payment.Submit' | translate}}
            </button>
          </mat-grid-list>
        </form>
      </section>
    </div>
  </mat-grid-list>
</div>
