<div *ngIf="!isTransactionStopped; else thankYouTemplate">
  <div *ngIf="transactionId === null" class="main-content flex-grow-1 marginn">
    <div>
    <mat-grid-list cols="3" rowHeight="1.5:1">
      <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
      <mat-grid-tile class="title1">{{'charging.charging' | translate}}</mat-grid-tile>
      <mat-grid-tile> 
        <div class="example-button-row">
          <button class="help" mat-flat-button (click)="redirectToEv24()">{{'qrcode.help' | translate}}</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="1" rowHeight="5:2">
      <mat-grid-tile>
        <div class="start-transaction">
          {{'charging.waiting' | translate}}
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <button class="start" mat-flat-button (click)="startTransaction()">{{'charging.start' | translate}}</button>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="4:2">
      <mat-grid-list cols="3" rowHeight="1:1">
        <mat-grid-tile>
          <div >
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-container">
            <span class="text-item title1">{{'charging.price' | translate}}:</span>
            <span class="text-item">0</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div >
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    <mat-grid-tile>
      <div class="details-container" style="width: 85%;">
        <span class="text-item title1">{{'charging.delivered' | translate}}:</span>
        <span class="text-item">0</span>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="details-container" style="width: 85%;">
          <span class="text-item title1">{{'charging.duration' | translate}}:</span>
          <span class="text-item">0</span>
        </div>
      </mat-grid-tile>
  </mat-grid-list>

    <!-- Additional details go here -->

    <mat-grid-list cols="1" rowHeight="24:3" class="backColor">
      <mat-grid-list cols="1" rowHeight="6:1">
        <mat-grid-tile class="title1">{{'charging.charging_Details' | translate}}</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">Id: </span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">{{'charging.charging_Details' | translate}}: 0</span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">{{'charging.location' | translate}}: {{ chargingStation.site.address.address1 }}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
  </div>

  <!-- Show transaction details if available -->
  <div class="main-content flex-grow-1 marginn" *ngIf="transactionDetails">
    <mat-grid-list cols="3" rowHeight="1.5:1">
      <mat-grid-tile class="title1">WattzHub</mat-grid-tile>
      <mat-grid-tile class="title1">{{'charging.charging' | translate}}</mat-grid-tile>
      <mat-grid-tile> 
        <div class="example-button-row">
          <button class="help" mat-flat-button (click)="redirectToEv24()">Help</button>
        </div>
      </mat-grid-tile>
    </mat-grid-list>

    <mat-grid-list cols="1" rowHeight="5:2">
      <mat-grid-tile>
        <div class="stop-transaction">
          {{'charging.charging...' | translate}}
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <button class="stop" mat-flat-button (click)="stopTransaction()">{{'charging.stop' | translate}}</button>
      </mat-grid-tile>
    </mat-grid-list>
    <mat-grid-list cols="2" rowHeight="4:2">
      <mat-grid-list cols="3" rowHeight="1:1">
        <mat-grid-tile>
          <div >
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div class="details-container">
            <span class="text-item title1">{{'charging.price' | translate}}:</span>
            <span class="text-item">{{transactionDetails.currentCumulatedRoundedPrice}} {{transactionDetails.priceUnit}}</span>
          </div>
        </mat-grid-tile>
        <mat-grid-tile>
          <div >
          </div>
        </mat-grid-tile>
      </mat-grid-list>
    <mat-grid-tile>
      <div class="details-container" style="width: 85%;">
        <span class="text-item title1">{{'charging.delivered' | translate}}:</span>
        <span class="text-item">{{energyInKWh}} kW.h</span>
      </div>
    </mat-grid-tile>
    <mat-grid-tile>
        <div class="details-container" style="width: 85%;">
          <span class="text-item title1">{{'charging.duration' | translate}}:</span>
          <span class="text-item">{{formattedDuration}}</span>
        </div>
      </mat-grid-tile>
  </mat-grid-list>

    <!-- Additional details go here -->

    <mat-grid-list cols="1" rowHeight="24:3" class="backColor">
      <mat-grid-list cols="1" rowHeight="6:1">
        <mat-grid-tile class="title1">{{'charging.charging_Details' | translate}}</mat-grid-tile>
      </mat-grid-list>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">Id: {{transactionDetails.id}}</span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">{{'qrcode.charging_Station' | translate}}: {{transactionDetails.chargeBoxID}}</span>
        </div>
      </mat-grid-tile>
      <mat-grid-tile>
        <div class="details-charging" style="width: 85%;">
          <span class="text-item title1">{{'charging.location' | translate}}: {{ chargingStation.site.address.address1 }}</span>
        </div>
      </mat-grid-tile>
    </mat-grid-list>
  </div>
</div>

<ng-template #thankYouTemplate>
  <div class="thank-you-container">
    <div class="thank-you-card">
      <h2 class="thank-you-title">{{'charging.thankyou' | translate}}</h2>
      <p class="thank-you-text">
        {{'charging.sessioFinished' | translate}}
      </p>
      <a [disabled]="!invoiceUrl" class="invoice-button" (click)="ViewInvoice()">
        {{isLoadingInvoice ? ('charging.chargement' | translate) : ('charging.ViewInvoice' | translate)}}
      </a>
    </div>
  </div>
</ng-template>
